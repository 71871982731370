

























































































































import { namespace } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { gsap } from "gsap";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import Thumbnail from "@/components/shared/Thumbnail.vue";

const contactModule = namespace("contact");
const pianosForSaleModule = namespace("pianosForSale");

@Component({
  components: {
    CoolLightBox,
    Thumbnail,
  },
})
export default class PianosForSale extends Vue {
  @contactModule.Getter iainPhoneNumber!: string;
  @contactModule.Getter companyName!: string;
  @contactModule.Getter socials!: Array<string>;

  displayContent = gsap.timeline();

  animate() {
    this.displayContent.play();
  }
  mounted() {
    this.displayContent.from("main .content", {
      duration: 0.5,
      opacity: 0,
    });
    this.animate();
  }
}
