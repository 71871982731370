



















import { Component, Prop, Vue } from "vue-property-decorator";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
@Component({
  components: {
    CoolLightBox,
  },
})
export default class Thumbnail extends Vue {
  @Prop() images!: Array<string>;
  @Prop() pianoName!: string;

  imageArray: any = [];
  index = null;
  mountImages() {
    this.imageArray = this.images;
  }
  mounted() {
    this.mountImages();
  }
}
